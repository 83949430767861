@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap");
*,
body,
p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --bgColor: #041c54;
  --btnColor: rgba(16, 41, 87, 0.7);
  --cardColor: rgb(49, 75, 132);
  --hoverFontColor: #96bdfc;
  --padding: 0 30px;
  --container: 1440px;
  --blurColor: rgba(208, 208, 208, 0.5);
}
/* box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
    -webkit-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17);
    -moz-box-shadow: 1px 1px 6px 1px rgba(255, 255, 255, 0.17); */

body {
  font-family: "Inter", sans-serif;
}

h1,
h2 {
  font-weight: 600;
  font-size: 28px;
  color: white;
  margin: 0;
}

h3 {
  font-size: 22px;
  font-weight: 600;
  color: white;
  margin: 0;
}

a,
div,
p,
span {
  font-weight: 400;
  font-size: 18px;
  color: white;
  /* line-height: 1.5; */
  margin: 0;
}
p {
  line-height: 1.5;
}

a {
  text-decoration: none;
}
@media (max-width: 900) {
  p,
  a,
  div,
  p,
  span {
    font-size: 14px;
    line-height: 1.2;
  }
  h1,
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 17px;
  }
}
@media (max-width: 600) {
  p,
  a,
  div,
  p,
  span {
    font-size: 10px;
    line-height: 1;
    color: red;
  }
  h1,
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 13px;
  }
}
